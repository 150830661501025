export const br = document.createElement("br");

export const toggleInput = (input, wrapper = document) => {
  const hideIcon = wrapper.querySelector(".password-icon");
  hideIcon?.addEventListener("click", () => {
    if (input.type === "password") {
      input.type = "text";
    } else {
      input.type = "password";
    }
    hideIcon.classList.toggle("icon-hide");
    hideIcon.classList.toggle("icon-show");
  });
};

/**
 * Функция для получения отображаемого значка валюты
 * @param {string} value
 * @return {string}
 * */
export const formatCurrency = (value) => {
  if (value === 'RUB') return '₽';
  if (value === 'USD') return '$';
  if (value === 'VID') return 'V';
}

/** Валидация колличества цифр в строке
 * @param {string} value - строка для валидации
 * @param {number} count - требуемое количество цифр
 * @return {boolean}
 * */
export const isValid = (value, count = 11) =>
  value.match(/\d/g)?.length === count;

/** Простой debouncer для инпутов
 * @param {Element} input - Инпут, на котрый вешаем debounce
 * @param {function} callback - Функция, которая исполнится по истечению времени
 * @param {number} wait - Сколько нужно ждать в мс
 * @return null
 * */
export const inputDebounced = (input, callback, wait = 1000) => {
  let timer;
  input?.addEventListener("input", () => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => callback(), wait);
  });
};

/** Тогглер для спиннера в модалке
 * @return null
 * */
export const modalLoading = () => {
  const modal = document.querySelector(".modal__loader");
  modal?.classList.toggle("modal__loader-enabled");
};

/** Функция для очистки ошибки с инпута
 * @param {Element} wrapper - Обёртка инпута
 * */
export const removeErrorHint = wrapper => {
  if (!wrapper) return;
  if (wrapper.classList.contains("input__error")) {
    wrapper.classList.remove("input__error");
    wrapper.querySelector(".input__error-hint")?.remove();
  }
};

/** Функция добавления ошибки к инпуту
 * @param {Element} wrapper - Обёртка инпута
 * @param {string} text - Текст ошибки
 * */
export const addErrorHint = (wrapper, text) => {
  if (wrapper.classList.contains("input__error")) {
    if (wrapper.querySelector(".input__error-hint")?.textContent === text) {
      return;
    } else {
      removeErrorHint(wrapper);
    }
  }
  wrapper.classList.add("input__error");
  const paragraph = document.createElement("p");
  paragraph.classList.add("input__error-hint");
  paragraph.appendChild(document.createTextNode(text));
  wrapper.appendChild(paragraph);
};

/** Функция для установки таймера
 * @param {number} time - Время таймера в секундах
 * @param {string} query - Селектор для таймера
 * @param {function} callback - Функция, вызванная при завершении отчёта
 * @param {number} interval - Интервал обновления таймера
 * @return null
 * */
export const setTimer = (time, query, callback, interval = 1000) => {
  const endDate = new Date();
  endDate.setSeconds(endDate.getSeconds() + time);
  const timerQuery = document.querySelector(query);
  if (timerQuery) {
    const id = setInterval(() => timer(), interval);
    const timer = () => {
      const time = ((new Date(endDate) - new Date()) / 1000).toFixed(0);
      timerQuery.innerHTML = `${time} сек.`;
      if (time <= 0) {
        clearInterval(id);
        callback();
      }
    };
  }
};

/** Простая валидация почты
 * @param {string} value - Значение
 * @return {boolean}
 * */
export const mailValidate = value => /\S+@\S+\.\S+/.test(value);

/** Валидация пароля
 * @param {string} value -
 * @return {{
 *   lowercaseSymbol,
 *   uppercaseSymbol,
 *   numberSymbol,
 *   minSymbol,
 *   success
 * }}
 * */
export const passValidate = value => {
  return {
    lowercaseSymbol: new RegExp("^(?=.*[a-z])").test(value),
    uppercaseSymbol: new RegExp("^(?=.*[A-Z])").test(value),
    numberSymbol: new RegExp("^(?=.*[0-9])").test(value),
    minSymbol: new RegExp("^(?=.{10,})").test(value),
    success: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})").test(
      value
    )
  };
};

/** Изменения чек-листа для пароля
 * @param isValid  {{
 *     lowercaseSymbol,
 *     uppercaseSymbol,
 *     numberSymbol,
 *     minSymbol,
 * }}
 * @return null
 * */
export const passChecklist = isValid => {
  const {lowercaseSymbol, uppercaseSymbol, numberSymbol, minSymbol} = isValid;
  const variousSymbols = document.querySelector(".variousSymbols");
  const symbolsRule = document.querySelector(".symbols");
  const minSymbolRule = document.querySelector(".minSymbol");

  if (lowercaseSymbol && uppercaseSymbol) {
    variousSymbols?.classList.add("rule-succeed");
  } else {
    variousSymbols?.classList.remove("rule-succeed");
  }
  if ((lowercaseSymbol || uppercaseSymbol) && numberSymbol) {
    symbolsRule?.classList.add("rule-succeed");
  } else {
    symbolsRule?.classList.remove("rule-succeed");
  }
  if (minSymbol) {
    minSymbolRule?.classList.add("rule-succeed");
  } else {
    minSymbolRule?.classList.remove("rule-succeed");
  }
};

/**
 * Функция для валидации ввода нового пароля.
 *  @param {NodeListOf<Element>} inputs - Массив из двух input'oв. Первый - основной, Второй для подтверждения.
 *  @param {NodeListOf<Element>} wrappers - Массив из обёрток данных инпутов.
 *  @return null;
 * */
export const doublePassInputs = (inputs, wrappers) => {
  inputs[0].addEventListener("input", () => {
    const {value} = inputs[0];
    const isValid = passValidate(value);
    passChecklist(isValid);
  });
  inputDebounced(
    inputs[0],
    () => {
      const {value} = inputs[0];
      const isValid = passValidate(value);
      const {lowercaseSymbol, uppercaseSymbol, numberSymbol, minSymbol} = isValid;
      if (
        (lowercaseSymbol && uppercaseSymbol && numberSymbol && minSymbol) ||
        value.length === 0
      ) {
        removeErrorHint(wrappers[0]);
      } else {
        addErrorHint(wrappers[0], "Пароль недостаточно безопасный");
      }
    },
    3000
  );
  inputDebounced(
    inputs[1],
    () => {
      if (inputs[0]?.value !== inputs[1]?.value) {
        addErrorHint(wrappers[1], "Пароли не совпадают");
      } else {
        removeErrorHint(wrappers[1]);
      }
    },
    3000
  );
};

export const inlineInputAddHint = (wrapper, text) => {
  if (!wrapper) return;
  if (!wrapper.classList.contains("inline-input__wrapper-error")) {
    wrapper.classList.add("inline-input__wrapper-error");
    const error_hint = document.createElement("p");
    error_hint.classList.add("input__error-hint");
    error_hint.classList.add("body-13");
    error_hint.appendChild(document.createTextNode(text));
    wrapper.appendChild(error_hint);
  }
};

export const inlineInputAddInfoHint = (wrapper, text) => {
  if (!wrapper) return;
  inlineInputRemoveInfoHint(wrapper);
  const hint = document.createElement("p");
  hint.classList.add("color-grey-med");
  hint.classList.add("body-13");
  hint.appendChild(document.createTextNode(text));
  wrapper.appendChild(hint);
};

export const inlineInputRemoveInfoHint = (wrapper) => {
  if (!wrapper) return;
  const hints = wrapper.querySelectorAll(".body-13.color-grey-med") || [];
  hints.forEach(hint => hint.remove());
};

export const inlineInputRemoveHint = wrapper => {
  inlineInputRemoveInfoHint(wrapper);
  if (!wrapper) return;
  if (wrapper.classList.contains("inline-input__wrapper-error")) {
    const err_hint = wrapper.querySelector(".input__error-hint");
    wrapper.classList.remove("inline-input__wrapper-error");
    err_hint?.remove();
  }
};

export const alertCloser = (wrapper = document) => {
  const alerts = wrapper.querySelectorAll(".alert");
  alerts.forEach(alert => {
    const close = alert.querySelector(".icon__close");
    close?.addEventListener("click", () => {
      alert.remove();
    });
  });
};

/**
 * Функция для показа/скрытия тоаста.
 *  @param {jQuery} toast - Имя файла.
 *  @return null;
 * */
export const toastAutoHide = toast => {
  setTimeout(() => toast.show(), 100);
  const delayTimeout = +toast.data("delay");
  setTimeout(() => toast.hide(), delayTimeout);
};

/**
 * Функция для получения расширения файла.
 *  @param {string} name - Имя файла.
 *  @return {string};
 * */
export const parseExtension = name => {
  const nameSplit = name.split(".");
  return nameSplit[nameSplit.length - 1];
};

/**
 * Функция для расчёта коммиссии.
 *  @param {boolean} axis - Имя файла.
 *  @param {number} commission - Имя файла.
 *  @param {number} constPay - Имя файла.
 *  @param {number} value - Имя файла.
 *  @return {string};
 * */
export const calcCommission = (axis, commission, constPay, value) => {
  if (axis) {
    return (+value.toFixed(2) * ((100 + commission) / 100) + (constPay / 100)).toFixed(2);
  }
  return ((+value.toFixed(2)) * (100 - commission) / 100 - (constPay / 100)).toFixed(2);
}

export const getCommission = (commission, constPay, value) => {
  return (+value.toFixed(2) * (commission / 100) + (constPay / 100)).toFixed(2);
}
export const getCommissionReverse = (commission, constPay, value) => {
  return ((+value.toFixed(2) + (constPay / 100)) * 100 / (100 - commission)).toFixed(2)
}

export const currencyFormat = (number) => new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
}).format(number);

export const currencyFormatCustom = (number, currency) => new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: currency,
}).format(number);

/**
 * @param {string} setValue
 * @return {boolean}
 * */
export const cardCheck = setValue => {
    let sum = 0;
    const num = String(setValue).replace(/\D/g, '');
    const isOdd = num.length % 2 !== 0;

    if ('' === num) return false;

    for (let i = 0; i < num.length; i++) {
      let n = parseInt(num[i], 10);
      sum += (isOdd | 0) === (i % 2) && 9 < (n *= 2) ? (n - 9) : n;
    }
  return 0 === (sum % 10);
};

let timer;

/**
 * @param cache
 * @param {string} name
 * @param {function} callback
 * @param {number} time
 */
export const debounceWithCache = (cache, name, callback, time) => {
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
  timer = setTimeout(async () => cache.set(name, await callback()), time);
}

export const getCompanyId = () => {
  if (document.location.href.includes('/company/')) {
    return document.location.href.split('/company/')[1].split('/actions/')[0];
  } else {
    return 0
  }
};